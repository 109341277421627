import React, { createContext, useContext, useReducer } from "react";

export const initialState = {
	data: {},
	theme: { color: "black" },
	isHover: false,
};

export const StateContext = createContext();

export const StateProvider = ({ reducer, initialState, children }) => (
	<StateContext.Provider value={useReducer(reducer, initialState)}>
		{children}
	</StateContext.Provider>
);

const useStateValue = () => useContext(StateContext);

export default useStateValue;
