import React, { Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import tokens from "tokens";
import { Link } from "react-router-dom";
// Utils
import getTokens from "utils/getTokens";

const Button = ({ className, type, onClick, children, wide, to }) => {
	return (
		<Fragment>
			{to ? (
				<To to={to} className={className} type={type}>
					<Container className={className} type={type} onClick={onClick} wide={wide}>
						{children}
					</Container>
				</To>
			) : (
				<Container className={className} type={type} onClick={onClick} wide={wide}>
					{children}
				</Container>
			)}
		</Fragment>
	);
};

// Styled components
const Container = styled.div`
	width: ${props => (props.wide ? "100%" : "auto")};
	color: ${props => props.theme.button[props.type].color};
	background-color: ${props => props.theme.button[props.type].bgColor};
	font-size: ${props => props.theme.button[props.type].size};
	padding: ${props => props.theme.button[props.type].padding};
	letter-spacing: ${props => props.theme.button[props.type].spacing};
	font-weight: 800;
	text-transform: uppercase;
`;

const To = styled(Link)`
	color: ${props => props.theme.button[props.type].color};
	font-size: ${props => props.theme.button[props.type].size};
	letter-spacing: ${props => props.theme.button[props.type].spacing};
	font-weight: 800;
	text-transform: uppercase;
	text-decoration: none;
	cursor: none;
`;

// Prop types
Button.defaultProps = {
	type: "primary",
	wide: false,
};
Button.propTypes = {
	type: PropTypes.oneOf(getTokens(tokens.button)),
	children: PropTypes.any,
	onClick: PropTypes.func,
	wide: PropTypes.bool,
};

export default Button;
