import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import tokens from "tokens";
// Utils
import getTokens from "utils/getTokens";

const Text = ({ className, type, children }) => {
	return (
		<Container className={className} type={type}>
			{children}
		</Container>
	);
};

// Styled components
const Container = styled.div`
	color: ${props => props.theme.color.black};
	font-family: ${props => props.theme.typography[props.type].family};
	font-size: ${props => props.theme.typography[props.type].size};
	line-height: ${props => props.theme.typography[props.type].lineHeight};
	position: relative;
`;

// Prop types
Text.defaultProps = {
	type: "base",
	wide: false,
};
Text.propTypes = {
	type: PropTypes.oneOf(getTokens(tokens.typography)),
	children: PropTypes.any,
	onClick: PropTypes.func,
	wide: PropTypes.bool,
};

export default Text;
