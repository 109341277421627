import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import useStateValue from "state";
// Assets
import logo from "assets/logo.svg";

const Nav = () => {
	const [{ isHover }, dispatch] = useStateValue();
	const handleLeave = () => dispatch({ type: "isHover", isHover: false });
	const handleHover = () => dispatch({ type: "isHover", isHover: true });
	return (
		<NavWrapper classname="nav">
			<Left>
				<Link to="/" onMouseOver={() => handleHover()} onMouseLeave={() => handleLeave()}>
					<Logo>
						<img src={logo} alt="Gabriele Rapone's logo" draggable="false" />
					</Logo>
				</Link>
			</Left>
			<Right></Right>
		</NavWrapper>
	);
};

// Styled components
const NavWrapper = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	border-bottom: 0.5px solid ${props => props.theme.color.grey_20};
	background-color: rgba(255, 255, 255, .3);
	backdrop-filter: blur(32px);
	padding: 0 ${props => props.theme.padding.desktop} 0 0;
	position: fixed;
	top: 0;
	z-index: 100;

	@media screen and (max-width: ${props => props.theme.device.mobile}) {
		/* padding: ${props => props.theme.padding.mobile}; */
	}
	@media screen and (max-width: ${props => props.theme.device.tablet}) {
		/* padding: ${props => props.theme.padding.tablet}; */
	}
`;

const Left = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-grow: 1;
`;

const Right = styled(Left)`
	justify-content: flex-end;
	padding-right: 25px;
	flex-grow: 5;
`;

const Logo = styled.div`
	width: 80px;
	height: 80px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-right: 0.5px solid ${props => props.theme.color.grey_20};
	pointer-events: none;

	img {
		transform: scale(1.05);
		display: inline-block;
		transform-style: preserve-3d;
		backface-visibility: hidden;
		transition: all 0.5s ease;
	}
`;

export default Nav;
