import React from "react";
import styled from "styled-components";
import useStateValue from "state";

const Pointer = () => {
	const [{ isHover }] = useStateValue();

	let clientX = -100;
	let clientY = -100;
	const cursor = document.getElementsByClassName("cursor");
	const circle = document.getElementsByClassName("circle");

	const initCursor = () => {
		document.addEventListener("mousemove", e => {
			clientX = e.clientX;
			clientY = e.clientY;
		});
		// Handles performance issues
		const render = () => {
			cursor[0].style.transform = `translate(${clientX}px, ${clientY}px) rotate(45deg)`;
			circle[0].style.transform = `translate(${clientX}px, ${clientY}px)`;
			requestAnimationFrame(render);
		};
		requestAnimationFrame(render);
	};
	initCursor();

	return (
		<Container>
			<Cursor className="cursor" hover={isHover} />
			<Circle className="circle" hover={isHover} />
		</Container>
	);
};

// Styled components
const Container = styled.div`
	width: 40px;
	height: 40px;
	position: absolute;
	z-index: 110;
	@media screen and (max-width: ${props => props.theme.device.mobile}) {
		display: none;
	}
`;

const Cursor = styled.div`
	width: 6px;
	height: 6px;
	left: -3px;
	top: -3px;
	background-color: ${props => props.theme.color.black};
	pointer-events: none;
	mix-blend-mode: difference;
	border-radius: 3px;
	opacity: ${props => (props.hover ? 0 : 1)};
	transition: opacity 500ms cubic-bezier(0.02, 1.23, 0.79, 1.02);
`;

const Circle = styled.div`
	width: ${props => (props.hover ? "80px" : "40px")};
	height: ${props => (props.hover ? "80px" : "40px")};
	left: ${props => (props.hover ? "-96%" : "-43%")};
	top: ${props => (props.hover ? "-105%" : "-57%")};
	border-radius: 50%;
	background-color: ${props => props.theme.color.accent};
	opacity: 0.3;
	transition: all 1000ms cubic-bezier(0.02, 1.23, 0.79, 1.02);
	position: relative;
	pointer-events: none;
`;

export default Pointer;
