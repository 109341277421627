import React from "react";
import styled from "styled-components";
import Scrollbar from "react-smooth-scrollbar";
// import { useSpring, animated } from "react-spring";
import useStateValue from "state";
// Components
import Text from "components/atoms/Text";
// import Dot from "components/atoms/Dot";
import Button from "components/atoms/Button";
import Space from "components/atoms/Space";
// Assets
import Username from "assets/UXGO/Username.png";
import Project from "assets/UXGO/Project.png";
import Preview from "assets/UXGO/Preview.png";
import Signup from "assets/UXGO/Signup.png";
import Editor from "assets/UXGO/Editor.png";
import UXGO_video from "assets/UXGO/UXGO_video.mp4";

const Projects = () => {
	const [{ isHover }, dispatch] = useStateValue();
	const handleLeave = () => dispatch({ type: "isHover", isHover: false });
	const handleHover = () => dispatch({ type: "isHover", isHover: true });

	// Video toggler
	const togglePlay = () => {
		const video = document.getElementById("uxgo_video");
		video.paused ? video.play() : video.pause();
		// video.paused
		//   ? (video.style.cursor = "url(" + playIcon + "), auto")
		//   : (video.style.cursor = "url(" + stopIcon + "), auto");
	};

	return (
		<Container>
			<SmoothScroll
				damping={0.05}
				thumbMinSize={0}
				renderByPixels={false}
				alwaysShowTracks={false}
				continuousScrolling={false}
			>
				<ProjectThumbnail>
					<Info>
						<Text type="h2">UXGO</Text>
						<Text className="subtitle">DESIGN. EDIT. GO.</Text>
						<Text classname="description">
							A product that aims to improve prototyping and collboration through a
							physical deck of cards. Each card represents a section of a website. The
							deck allows the designer, developer or the whole team to build websites
							offline and the mobile app turns the cards into a real website, instantly.
						</Text>
						<Text type="base" className="date">
							May 2018 to April 2020
						</Text>
						<Text type="base" className="role">
							Product Design and React Development
						</Text>
					</Info>
				</ProjectThumbnail>

				<Wall>
					<GalleryItem src={Signup} alt="Signup screen" />
					<GalleryItem src={Username} alt="Username screen" />
					<GalleryItem src={Project} alt="Project screen" />
					<GalleryItem src={Preview} alt="Preview screen" />
				</Wall>

				<ProjectThumbnail>
					<Info>
						<Text type="h2">Finding the target</Text>
						<Text type="base" classname="description">
							When we started we thought we knew the target we were designing for. Of
							course, it turned out to be a bad hire. The people who needed our solution
							was usually people who wanted to learn how to create a website, marketers
							who needed to quickly obtain landing pages or professional that needed to
							sell the idea of a digital solution.
						</Text>
						<Minus />
					</Info>
				</ProjectThumbnail>

				<ProjectThumbnail>
					<Info>
						<Text type="h2">From 0€ MVP to 145.000€ funding</Text>
						<Text type="base" className="description">
							Everything started with a problem to solve: let designers and developers
							better communicate with stakeholders and create solutions together with a
							practical approach. We needed a tool to brainstorm and suddenly jump to the
							technical solution.
							<Space space="20" />
							Immagine to brainstorm ideas for a web page with a bunch of cards, scan the
							cards with your phone and then send the editable website to developers or
							finishing it up on your own. With that in mind we created our first protoype
							in less more than one month, we printed the first deck of card that used the
							barcode to let the mobile app recognize it. With that deck we obtained our
							first customers during an exhibition. Few months later we had the
							opportunity to join the most powerful acceleration program in Italy and have
							been funded by 145.000€ that allowed us to scale the product as we imagined
							it.
						</Text>
						<Minus />
					</Info>
				</ProjectThumbnail>

				<ProjectThumbnail className="gallery">
					<Info>
						<Text type="h2">A multiplatform product</Text>
						<Text type="base" className="description uxgo-description-4">
							The creation of a website is a need that is not easy to solve exclusivley on
							a smartphone, especially if you want let the users to reach a certain level
							of detail. That's why we thought about a multiplatform software that let the
							user finish the work on larger devices. I heard about lots of web builders
							that exist with promise of let you create web pages on your phone, which
							sounds good. The problem is that often these products lack in flexibility
							and doesn't have fundamental features. Making use of multiple platforms
							including the physical approach allowed us to provide a different and
							unified experience for seveal kinds of professionals.
						</Text>
					</Info>
					<Monitor src={Editor} />
				</ProjectThumbnail>

				<ProjectThumbnail>
					<Info>
						<Text type="h2">Shaping the product</Text>
						<Text type="base" classname="description">
							I started by talking about how hard was to find the right target, well it
							completely determined the outcome of the product. We started by thinking of
							a solution that front-end developers could use to increase the speed of
							websites production and that lead us in building an highly technical
							solution. The UI wasn't that simple because we didn't have only the
							simplicity in mind, we thought we should offer a powerful solution. Things
							changed when we had the opportunity to invest money in research and
							prototyping. Today UXGO is what you can see on its website or in the
							APP/Play Store or in the images on this website.
						</Text>
						<Space space="40" />
					</Info>
				</ProjectThumbnail>

				<ProjectThumbnail>
					<VideoWrapper
						onMouseOver={() => handleHover()}
						onMouseLeave={() => handleLeave()}
					>
						<video
							id="uxgo_video"
							width="100%"
							height="100%"
							controls={false}
							loop={true}
							muted
							onClick={() => togglePlay()}
						>
							<source src={UXGO_video} type="video/mp4" />
						</video>
					</VideoWrapper>
				</ProjectThumbnail>
			</SmoothScroll>

			<div onMouseOver={() => handleHover()} onMouseLeave={() => handleLeave()}>
				<Back
					type="tertiary"
					to="/"
					onMouseOver={() => handleHover()}
					onMouseLeave={() => handleLeave()}
				>
					Home
				</Back>
			</div>
			<div onMouseOver={() => handleHover()} onMouseLeave={() => handleLeave()}>
				<Next
					type="tertiary"
					to="/OneFlag"
					onMouseOver={() => handleHover()}
					onMouseLeave={() => handleLeave()}
				>
					Next
				</Next>
			</div>
		</Container>
	);
};

// Styled components
const Container = styled.div`
	position: absolute;
	height: 100vh;
	width: 100%;
	overflow: hidden;
	.gallery {
		flex-direction: column;
		> div:first-child {
			> div:first-child {
				margin-bottom: 10px;
			}
		}
	}
`;

const SmoothScroll = styled(Scrollbar)`
	height: 100vh;
	overflow: scroll;
	position: relative;
	overflow-x: hidden;
	.scroll-content {
		> div {
			width: 100%;
		}
	}
	.scrollbar-track {
		display: none !important;
	}
`;

const Back = styled(Button)`
	position: fixed;
	left: 20px;
	top: 100px;
	@media screen and (max-width: ${props => props.theme.device.mobile}) {
		left: 5px;
		top: 90px;
	}
`;

const Next = styled(Button)`
	position: fixed;
	right: 20px;
	top: 100px;
	@media screen and (max-width: ${props => props.theme.device.mobile}) {
		right: 5px;
		top: 90px;
	}
`;

const ProjectThumbnail = styled.div`
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin: 0 auto;
	@media screen and (max-width: ${props => props.theme.device.mobile}) {
		width: 86%;
	}
	&:first-child {
		padding-top: 124px;
		@media screen and (max-width: ${props => props.theme.device.mobile}) {
			padding-top: 150px;
		}
	}
	.description {
		img {
			width: 50px;
		}
	}
`;

const Info = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	max-width: 500px;
	z-index: 100;
	> div:first-child {
		margin-bottom: 16px;
		@media screen and (max-width: ${props => props.theme.device.mobile}) {
			font-size: 7vw;
		}
	}
	.date {
		color: ${props => props.theme.color.grey_40};
		margin: 8px 0 5px 0;
	}
	.subtitle {
		color: ${props => props.theme.color.grey_40};
	}
	.role {
		margin-top: 8px;
		font-family: Rajdhani-SemiBold;
	}
	@media screen and (max-width: ${props => props.theme.device.mobile}) {
	}
`;

const Monitor = styled.img`
	width: 100%;
	margin: 40px 0 100px 0;
	box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
	border-radius: 12px;
	@media screen and (max-width: ${props => props.theme.device.mobile}) {
		width: 100%;
	}
`;

const Wall = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: #f3f9f9;
	height: 700px;
	overflow: hidden;
	margin: 90px 0;
	flex-wrap: wrap;
	max-height: 80vh;
`;

const GalleryItem = styled.img`
	min-width: 150px;
	width: 20%;
	max-width: 350px;
`;

const VideoWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 0 200px 0;
	box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
	border-radius: 12px;
	overflow: hidden;
`;

const Minus = styled.div`
	width: 42px;
	height: 2px;
	background-color: ${props => props.theme.color.accent};
	margin: 62px 0;
`;

export default Projects;
