import React from "react";
import styled from "styled-components";
import useStateValue from "state";
// Components
import Button from "components/atoms/Button";
// Assets
import Resume from "assets/Gabriele_Rapone_CV_light.pdf";

const Footer = () => {
	const [{ isHover }, dispatch] = useStateValue();
	const handleLeave = () => dispatch({ type: "isHover", isHover: false });
	const handleHover = () => dispatch({ type: "isHover", isHover: true });

	// scrollable.onscroll = () => myFunction();

	// const myFunction = () => {
	// 	const winScroll = scrollable.scrollTop || scrollable.scrollTop;
	// 	const height = scrollable.scrollHeight - scrollable.clientHeight;
	// 	const scrolled = (winScroll / height) * 100;
	// 	document.getElementById("scroll-indicator").style.width = scrolled + "%";
	// };

	return (
		<Container>
			<ContactsWrapper>
				<Btn type="tertiary">
					<a
						onMouseOver={() => handleHover()}
						onMouseLeave={() => handleLeave()}
						href="https://www.instagram.com/gabrielerapone/"
						target="_blank"
						rel="noopener noreferrer"
					>
						Instagram
					</a>
				</Btn>
				<Btn type="tertiary">
					<a
						onMouseOver={() => handleHover()}
						onMouseLeave={() => handleLeave()}
						href="https://www.linkedin.com/in/gabriele-rapone/"
						target="_blank"
						rel="noopener noreferrer"
					>
						LinkedIn
					</a>
				</Btn>
				<Btn type="tertiary">
					<a
						onMouseOver={() => handleHover()}
						onMouseLeave={() => handleLeave()}
						href="mailto:hello@gabrielerapone.xyz"
					>
						hello@gabrielerapone.xyz
					</a>
				</Btn>
			</ContactsWrapper>

			<Btn type="primary">
				<a
					onMouseOver={() => handleHover()}
					onMouseLeave={() => handleLeave()}
					href={Resume}
					download="Gabriele_Rapone_CV"
				>
					Download resume
				</a>
			</Btn>
		</Container>
	);
};

// Styled components
const Container = styled.div`
	width: 100%;
	height: 52px;
	border-top: 0.5px solid ${props => props.theme.color.grey_20};
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	position: fixed;
	bottom: 0;
	background-color: rgba(255, 255, 255, 0.3);
	backdrop-filter: blur(32px);
	a {
		text-decoration: none;
		color: ${props => props.theme.color.black};
	}
	> div:nth-child(2) {
		a {
			color: ${props => props.theme.color.white};
		}
	}
	@media screen and (max-width: ${props => props.theme.device.mobile}) {
		display: none;
	}
`;

const ContactsWrapper = styled.div`
	padding-left: 30px;
	display: flex;
	flex-direction: row;
	> div {
		font-weight: 600;
		font-size: 12px;
		&:first-child {
			padding-left: 0;
		}
	}
`;

// const Scrollbar = styled.div`
// 	width: 1%;
// 	height: 1px;
// 	background-color: ${props => props.theme.color.accent};
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// `;

const Btn = styled(Button)``;

export default Footer;
