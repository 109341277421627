import React from "react";
import styled from "styled-components";
import Scrollbar from "react-smooth-scrollbar";
// import { useSpring, animated } from "react-spring";
import useStateValue from "state";
// Components
import Text from "components/atoms/Text";
// import Dot from "components/atoms/Dot";
import Button from "components/atoms/Button";
import Space from "components/atoms/Space";
// Assets
import OneFlag_Home from "assets/OneFlag/Home-screen.jpg";
import SwipeTinderLike from "assets/OneFlag/Swipe.jpg";

const Projects = () => {
	const [{ isHover }, dispatch] = useStateValue();
	const handleLeave = () => dispatch({ type: "isHover", isHover: false });
	const handleHover = () => dispatch({ type: "isHover", isHover: true });

	return (
		<Container>
			<SmoothScroll
				damping={0.05}
				thumbMinSize={0}
				renderByPixels={false}
				alwaysShowTracks={false}
				continuousScrolling={true}
			>
				<ProjectThumbnail>
					<Info>
						<Text type="h2">OneFlag</Text>
						<Text className="subtitle">
							A FLAG FOR US {/*<span role="img">🌎</span>*/}
						</Text>
						<Text classname="description">
							This projects is a non profit initiative that aims to find the flag for the
							world by inviting people to propose a design for the flag of the Earth.
							Everyone can participate and everyone can vote. This contest will last 365
							days starting from April 22nd. On the same day of the 2021 a winner will be
							announced.
							<br />
							<a
								href="https://oneflag.world"
								target="_blank"
								rel="noopener noreferrer"
								onMouseOver={() => handleHover()}
								onMouseLeave={() => handleLeave()}
							>
								oneflag.world
							</a>
						</Text>
						<Text type="base" className="date">
							March to April 2020
						</Text>
						<Text type="base" className="role">
							UX Design and Front-end Development
						</Text>
					</Info>
				</ProjectThumbnail>

				<Wall>
					<img src={OneFlag_Home} width="100%" alt="Home screen of OneFlag" />
				</Wall>

				<ProjectThumbnail className="gallery">
					<Info>
						<Text type="h2">Interaction</Text>
						<Text type="base" className="description uxgo-description-4">
							Initially we thought it could be better to show the whole list of flags so
							people could vote only for the flags they likes.
							<Space space="20" />
							The problem was that doing so, only a certain kind of design would "pop" to
							the user's eye which could influence the results. We needed to let the user
							answer for the question we were asking for: "do you think this could be the
							flag of the Earth?". We ended up to use the same interaction of Tinder to
							mantain focus and get the needed answer.
						</Text>
					</Info>
					<Monitor src={SwipeTinderLike} />
				</ProjectThumbnail>
			</SmoothScroll>
			<div onMouseOver={() => handleHover()} onMouseLeave={() => handleLeave()}>
				<Back
					type="tertiary"
					to="/"
					onMouseOver={() => handleHover()}
					onMouseLeave={() => handleLeave()}
				>
					Home
				</Back>
			</div>
			<div onMouseOver={() => handleHover()} onMouseLeave={() => handleLeave()}>
				<Next
					type="tertiary"
					to="/Heroport"
					onMouseOver={() => handleHover()}
					onMouseLeave={() => handleLeave()}
				>
					Next
				</Next>
			</div>
		</Container>
	);
};

// Styled components
const Container = styled.div`
	position: absolute;
	height: 100vh;
	width: 100%;
	overflow: hidden;
	.gallery {
		img {
			box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
			border-radius: 12px;
		}
		flex-direction: column;
		> div:first-child {
			> div:first-child {
				margin-bottom: 10px;
			}
		}
	}
`;

const SmoothScroll = styled(Scrollbar)`
	height: 100vh;
	overflow: scroll;
	position: relative;
	overflow-x: hidden;
	.scroll-content {
		> div {
			width: 100%;
		}
	}
	.scrollbar-track {
		display: none !important;
	}
`;

const Back = styled(Button)`
	position: fixed;
	left: 20px;
	top: 100px;
	@media screen and (max-width: ${props => props.theme.device.mobile}) {
		left: 5px;
		top: 90px;
	}
`;

const Next = styled(Button)`
	position: fixed;
	right: 20px;
	top: 100px;
	@media screen and (max-width: ${props => props.theme.device.mobile}) {
		right: 5px;
		top: 90px;
	}
`;

const ProjectThumbnail = styled.div`
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin: 0 auto;
	@media screen and (max-width: ${props => props.theme.device.mobile}) {
		width: 86%;
	}
	&:first-child {
		padding-top: 124px;
		@media screen and (max-width: ${props => props.theme.device.mobile}) {
			padding-top: 150px;
		}
	}
	.description {
		img {
			width: 50px;
		}
	}
`;

const Info = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	max-width: 500px;
	z-index: 100;
	> div:first-child {
		margin-bottom: 16px;
		@media screen and (max-width: ${props => props.theme.device.mobile}) {
			font-size: 7vw;
		}
	}
	.date {
		color: ${props => props.theme.color.grey_40};
		margin: 8px 0 5px 0;
	}
	.subtitle {
		color: ${props => props.theme.color.grey_40};
	}
	.role {
		margin-top: 0px;
		font-family: Rajdhani-SemiBold;
	}
	@media screen and (max-width: ${props => props.theme.device.mobile}) {
	}
`;

const Monitor = styled.img`
	width: 100%;
	margin: 40px 0 100px 0;
	@media screen and (max-width: ${props => props.theme.device.mobile}) {
		width: 100%;
	}
`;

const Wall = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: #f3f9f9;
	max-height: 80vh;
	overflow: hidden;
	margin: 90px 0;
	flex-wrap: wrap;
`;

export default Projects;
