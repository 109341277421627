import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import useStateValue from "state";
// Components
import Text from "components/atoms/Text";
// import Dot from "components/atoms/Dot";
import Space from "components/atoms/Space";

const Welcome = () => {
	const [{ isHover }, dispatch] = useStateValue();
	const handleLeave = () => dispatch({ type: "isHover", isHover: false });
	const handleHover = () => dispatch({ type: "isHover", isHover: true });

	return (
		<Container>
			<TransitionSlide>
				<Hello>
					<Text type="h1">Product Designer</Text>
					<Text type="h1">Gabriele rapone</Text>
					<Text type="h1">REACT DEVELOPER</Text>
					<Space space="15" />
					<Text type="h3">Projects</Text>
					<Space space="10" />
					<div className="projects">
						<LinkToProject
							to="UXGO/"
							onMouseOver={() => handleHover()}
							onMouseLeave={() => handleLeave()}
						>
							<Paragraph>UXGO</Paragraph>
						</LinkToProject>
						<LinkToProject
							to="OneFlag/"
							onMouseOver={() => handleHover()}
							onMouseLeave={() => handleLeave()}
						>
							<Paragraph>OneFlag</Paragraph>
						</LinkToProject>

						<LinkToProject
							to="Heroport/"
							onMouseOver={() => handleHover()}
							onMouseLeave={() => handleLeave()}
						>
							<Paragraph>Heroport</Paragraph>
						</LinkToProject>
						<LinkToProject
							to="Ferrero/"
							onMouseOver={() => handleHover()}
							onMouseLeave={() => handleLeave()}
						>
							<Paragraph>Ferrero</Paragraph>
						</LinkToProject>
					</div>
				</Hello>
				<About>
					<Text type="h3">About me</Text>
					<Space space="10" />
					<Paragraph>
						I’m a Rome based full-stack product designer and frontend developer with
						corporate experience. Focusing on scalable design to solve people's problems.
						The idea to develop what comes out of my mind always fascinated me. Oh, I’m a
						startup founder too.
					</Paragraph>
				</About>
			</TransitionSlide>
		</Container>
	);
};

// Styled components
const Container = styled.div`
	height: 100vh;
	position: absolute;
	width: 100%;
	overflow: hidden;
`;
const TransitionSlide = styled.div`
	position: absolute;
	top: 0;
	width: 100%;
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	overflow: hidden;
`;
const Hello = styled.div`
	width: calc(100% - 60px);
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	padding-top: 120px;
	overflow: hidden;
	margin: 0 auto;
	@media screen and (max-width: ${props => props.theme.device.mobile}) {
		width: 90%;
		padding-top: 100px;
	}
	> div:nth-child(1),
	> div:nth-child(2),
	> div:nth-child(3) {
		font-size: 5.4vw;
		margin: 0.5vw 0;
		line-height: 80%;
		@media screen and (max-width: ${props => props.theme.device.mobile}) {
			font-size: 8vw;
		}
	}
	> div:nth-child(2) {
		-webkit-text-stroke: 2px black;
		color: transparent;
		@media screen and (max-width: ${props => props.theme.device.mobile}) {
			-webkit-text-stroke: 1px black;
		}
	}
	.projects {
		display: flex;
		justify-content: space-between;
		align-items: center;
		> a div {
			font-size: 1.25rem;
			font-family: Rajdhani-SemiBold;
			margin: 0 20px;
			color: ${props => props.theme.color.accent};
			transition: color ${props => props.theme.transition.normal};
			&:hover {
				color: ${props => props.theme.color.black};
			}
			&:first-child {
				margin-left: 0;
			}
		}
	}
`;

const About = styled.div`
	width: calc(100% - 60px);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin: 0 auto 70px auto;
	@media screen and (max-width: ${props => props.theme.device.mobile}) {
		margin: 0 auto 20px auto;
	}
	img {
		position: absolute;
		right: -80px;
		bottom: 70px;
		width: 354px;
	}

	@media screen and (max-width: ${props => props.theme.device.mobile}) {
		width: 90%;
		> div:nth-child(3) {
			font-size: 5vw;
		}
	}
`;

const Paragraph = styled(Text)`
	max-width: 500px;
`;

const LinkToProject = styled(Link)`
	text-decoration: none;
	width: 100%;
`;

export default Welcome;
