import React from "react";
import styled from "styled-components";
import Scrollbar from "react-smooth-scrollbar";
import useStateValue from "state";
// Components
import Text from "components/atoms/Text";
// import Dot from "components/atoms/Dot";
import Button from "components/atoms/Button";
import Space from "components/atoms/Space";

import Cover from "assets/Ferrero/Cover.jpg";
import WF1 from "assets/Ferrero/WF1.jpg";
import WF2 from "assets/Ferrero/WF2.jpg";
import WF3 from "assets/Ferrero/WF3.jpg";
import WF4 from "assets/Ferrero/WF4.png";
import UI1 from "assets/Ferrero/UI1.jpg";
import UI2 from "assets/Ferrero/UI2.jpg";
import UI3 from "assets/Ferrero/UI3.jpg";
import UI4 from "assets/Ferrero/UI4.jpg";
import MockupVideo from "assets/Ferrero/video.mp4";

const Projects = () => {
	const [{ isHover }, dispatch] = useStateValue();
	const handleLeave = () => dispatch({ type: "isHover", isHover: false });
	const handleHover = () => dispatch({ type: "isHover", isHover: true });

	// Video toggler
	const togglePlay = () => {
		const video = document.getElementById("uxgo_video");
		video.paused ? video.play() : video.pause();
	};

	return (
		<Container>
			<SmoothScroll
				damping={0.05}
				thumbMinSize={0}
				renderByPixels={false}
				alwaysShowTracks={false}
				continuousScrolling={false}
			>
				<ProjectThumbnail>
					<Info>
						<Text type="h2">Ferrero</Text>
						<Text className="subtitle">REDEFINING THE WEB PRESENCE</Text>
						<Text className="description">
							One of the first projects I made as a student.
							<Space space="12" />
							The brief consisted in a re-design of the Ferrero website due to an old
							version that wasn't optimized for mobile devices and the UI didn't follow
							the brand guidelines causing users don't recognize the brand while
							navigating the website.
						</Text>
						<Text type="base" className="date">
							February to May 2017
						</Text>
						<Text type="base" className="role">
							Web Design - case study
						</Text>
					</Info>
				</ProjectThumbnail>

				<Wall>
					<img src={Cover} width="100%" alt="Mockup of Ferrero project" />
				</Wall>

				<ProjectThumbnail className="gallery">
					<Info>
						<Text type="h2">Design</Text>
						<Text className="description uxgo-description-4">
							For this project I focused on the overall user experience running A/B tests
							with two different wireframes to choose the best solution. On one hand we
							had a more traditional solution while on the other hand we had a wireframe
							(see screenshots below) that was a bit more extravagant and ended up to be
							the choice we went for.
						</Text>
					</Info>
				</ProjectThumbnail>

				<Wall>
					<GalleryItem src={WF1} alt="Wireframe 1" />
					<GalleryItem src={WF2} alt="Wireframe 2" />
					<GalleryItem src={WF3} alt="Wireframe 3" />
					<GalleryItem src={WF4} alt="Wireframe 4" />
				</Wall>

				<ProjectThumbnail className="gallery">
					<Info>
						<Space space="0" />
						<Text className="description uxgo-description-4">
							Once it came to design a captivating UI I thought to create a custom bicolor
							icon set to give the overall experience a different flavour and to reinforce
							the similarity with the brand.
						</Text>
					</Info>
				</ProjectThumbnail>

				<Wall>
					<GalleryItem src={UI3} alt="User Interface 1" />
					<GalleryItem src={UI1} alt="User Interface 2" />
					<GalleryItem src={UI4} alt="User Interface 3" />
					<GalleryItem src={UI2} alt="User Interface 4" />
				</Wall>

				<Space space="160" />

				<ProjectThumbnail>
					<VideoWrapper
						onMouseOver={() => handleHover()}
						onMouseLeave={() => handleLeave()}
					>
						<video
							id="uxgo_video"
							width="100%"
							height="100%"
							controls={false}
							loop={true}
							muted
							onClick={() => togglePlay()}
						>
							<source src={MockupVideo} type="video/mp4" />
						</video>
					</VideoWrapper>
				</ProjectThumbnail>
			</SmoothScroll>
			<div onMouseOver={() => handleHover()} onMouseLeave={() => handleLeave()}>
				<Back
					type="tertiary"
					to="/"
					onMouseOver={() => handleHover()}
					onMouseLeave={() => handleLeave()}
				>
					Home
				</Back>
			</div>
			<div onMouseOver={() => handleHover()} onMouseLeave={() => handleLeave()}>
				<Next
					type="tertiary"
					to="/OneFlag"
					onMouseOver={() => handleHover()}
					onMouseLeave={() => handleLeave()}
				>
					Next
				</Next>
			</div>
		</Container>
	);
};

// Styled components
const Container = styled.div`
	position: absolute;
	height: 100vh;
	width: 100%;
	overflow: hidden;
	flex-direction: column;
	> div:first-child {
		> div:first-child {
			margin-bottom: 10px;
		}
	}
`;

const SmoothScroll = styled(Scrollbar)`
	height: 100vh;
	overflow: scroll;
	position: relative;
	overflow-x: hidden;
	.scroll-content {
		> div {
			width: 100%;
			/* overflow: hidden; */
		}
	}
	.scrollbar-track {
		display: none !important;
	}
`;

const Back = styled(Button)`
	position: fixed;
	left: 20px;
	top: 100px;
	@media screen and (max-width: ${props => props.theme.device.mobile}) {
		left: 5px;
		top: 90px;
	}
`;

const Next = styled(Button)`
	position: fixed;
	right: 20px;
	top: 100px;
	@media screen and (max-width: ${props => props.theme.device.mobile}) {
		right: 5px;
		top: 90px;
	}
`;

const ProjectThumbnail = styled.div`
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin: 0 auto;
	@media screen and (max-width: ${props => props.theme.device.mobile}) {
		width: 86%;
	}
	&:first-child {
		padding-top: 124px;
		@media screen and (max-width: ${props => props.theme.device.mobile}) {
			padding-top: 150px;
		}
	}
	.description {
		img {
			width: 50px;
		}
	}
`;

const Info = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	max-width: 500px;
	z-index: 100;
	.photo-description {
		color: ${props => props.theme.color.grey_30};
		text-align: center;
		width: 100%;
	}
	img {
		box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
		border-radius: 12px;
	}
	> div:first-child {
		margin-bottom: 16px;
		@media screen and (max-width: ${props => props.theme.device.mobile}) {
			font-size: 7vw;
		}
	}
	.date {
		color: ${props => props.theme.color.grey_40};
		margin: 8px 0 5px 0;
	}
	.subtitle {
		color: ${props => props.theme.color.grey_40};
	}
	.role {
		margin-top: 0px;
		font-family: Rajdhani-SemiBold;
	}
	@media screen and (max-width: ${props => props.theme.device.mobile}) {
	}
`;

const Wall = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: #f9f9f9;
	overflow: hidden;
	margin: 90px 0;
	flex-wrap: wrap;
	max-height: 80vh;
`;

const GalleryItem = styled.img`
	min-width: 150px;
	width: 20%;
	max-width: 350px;
	margin: 0 18px 18px 0;
`;

const VideoWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 0 0 200px 0;
	box-shadow: 0 0 32px rgba(0, 0, 0, 0.1);
	border-radius: 12px;
	overflow: hidden;
`;

export default Projects;
