import React from "react";
import styled from "styled-components";

const Space = ({ space }) => <Container space={space} />;

// Styled components
const Container = styled.div`
	height: ${props => props.space}px;
	width: ${props => props.space}px;
`;

export default Space;
