import React from "react";
import styled from "styled-components";
import Scrollbar from "react-smooth-scrollbar";
// import mediumZoom from "medium-zoom";
// import { useSpring, animated } from "react-spring";
import useStateValue from "state";
// Components
import Text from "components/atoms/Text";
// import Dot from "components/atoms/Dot";
import Button from "components/atoms/Button";
import Space from "components/atoms/Space";
// Assets
import Persona from "assets/Heroport/Persona.jpg";
import CJ from "assets/Heroport/Customer-Journey.jpg";

import UI1 from "assets/Heroport/UI-1.png";
import UI2 from "assets/Heroport/UI-2.png";
import UI3 from "assets/Heroport/UI-3.jpg";
import UI4 from "assets/Heroport/UI-4.jpg";

const Projects = () => {
	const [{ isHover }, dispatch] = useStateValue();
	const handleLeave = () => dispatch({ type: "isHover", isHover: false });
	const handleHover = () => dispatch({ type: "isHover", isHover: true });

	// mediumZoom(document.querySelector("#zoomable"));

	return (
		<Container>
			<SmoothScroll
				damping={0.05}
				thumbMinSize={0}
				renderByPixels={false}
				alwaysShowTracks={false}
				continuousScrolling={false}
			>
				<ProjectThumbnail>
					<Info>
						<Text type="h2">Heroport</Text>
						<Text className="subtitle">EXPERIENCE NON-PLACES</Text>
						<Text classname="description">
							If you should rate the user experience of a traveler what could be one of
							the greatest pain point? Right. The airport.
							<Space space="12" />
							The brief consisted in designing a solution for non-places (aiports,
							stations, ports...). <br />
							With this project I focused on the preliminary processes to be able to
							create a real solution and not only a bunch of good screens.
						</Text>
						<Text type="base" className="date">
							March to May 2018
						</Text>
						<Text type="base" className="role">
							Product Design - case study
						</Text>
						<Minus />
					</Info>
				</ProjectThumbnail>

				<ProjectThumbnail>
					<Info>
						<Text type="h2">User Research</Text>
						<Text type="base" classname="description">
							I've been involved in each part of the design proccess from the initial
							research starting with the ideation of proto-personas.
							<Space space="12" />
							To better understand how people interact with non-places and to empathize
							with them it was necessary to run some contextual observation, interviews
							and customer journey map. Once gathered all the informations together the
							first ideas was coming out and I decided to focus on the customer experience
							in the airport as data guided me in that direction.
						</Text>
						<Space space="40" />
						<img width="100%" src={Persona} alt="Persona" id="zoomable" />
						<Space space="10" />
						<Text className="photo-description" type="detail">
							Proto-persona
						</Text>
						<Space space="40" />
						<img width="100%" src={CJ} alt="Customer journey" id="zoomable" />
						<Space space="10" />
						<Text className="photo-description" type="detail">
							Customer Journey Map (as-is)
						</Text>
						<Space space="40" />
						<Minus />
					</Info>
				</ProjectThumbnail>

				<ProjectThumbnail className="gallery">
					<Info>
						<Text type="h2">Design</Text>
						<Text type="base" className="description uxgo-description-4">
							When it comes to design for such a wide range of users it's never easy to
							find the right compromise in terms of technologies, tone of voice and
							features. For sure it's not easy to propose a solution that users need to
							download from the App/Play Store and that's why I thought to exploit web
							features to give travelers a better experience.
							<Space space="20" />
							Once to the airport the people can scan a QR-code in front of the opening
							doors, a webpage shows up and they instantly have an interactive map that
							guide them wherever they need to go. Restroom, fast food, gate, with
							heroport it's easy to reach the spot. The system also sends suggestions
							depending on where the user is to make him buy something at the duty free
							for example.
						</Text>
					</Info>
				</ProjectThumbnail>

				<Wall>
					<GalleryItem src={UI3} id="zoomable" alt="Persona" />
					<GalleryItem src={UI1} id="zoomable" alt="Persona" />
					<GalleryItem src={UI4} id="zoomable" alt="Persona" />
					<GalleryItem src={UI2} id="zoomable" alt="Persona" />
				</Wall>

				<ProjectThumbnail className="gallery">
					<Info>
						<Text type="h2">Hiccups</Text>
						<Text type="base" className="description uxgo-description-4">
							Only once the project was finished some technological problems came out. For
							example it's quite difficult to track the Y axis position of the user. Most
							of the airports have more than one floor which makes it hard for this
							solution to work smoothly for every airport. A solution for this problem
							could be to put different QR-Code for every floor of the airport, each one
							knows how to guide the user on that specific floor.
						</Text>
					</Info>
				</ProjectThumbnail>
				<Space space="160" />
			</SmoothScroll>
			<div onMouseOver={() => handleHover()} onMouseLeave={() => handleLeave()}>
				<Back
					type="tertiary"
					to="/"
					onMouseOver={() => handleHover()}
					onMouseLeave={() => handleLeave()}
				>
					Home
				</Back>
			</div>
			<div onMouseOver={() => handleHover()} onMouseLeave={() => handleLeave()}>
				<Next
					type="tertiary"
					to="/Ferrero"
					onMouseOver={() => handleHover()}
					onMouseLeave={() => handleLeave()}
				>
					Next
				</Next>
			</div>
		</Container>
	);
};

// Styled components
const Container = styled.div`
	position: absolute;
	height: 100vh;
	width: 100%;
	overflow: hidden;
	flex-direction: column;
	> div:first-child {
		> div:first-child {
			margin-bottom: 10px;
		}
	}
`;

const SmoothScroll = styled(Scrollbar)`
	height: 100vh;
	overflow: scroll;
	position: relative;
	overflow-x: hidden;
	.scroll-content {
		> div {
			width: 100%;
		}
	}
	.scrollbar-track {
		display: none !important;
	}
`;

const Back = styled(Button)`
	position: fixed;
	left: 20px;
	top: 100px;
	@media screen and (max-width: ${props => props.theme.device.mobile}) {
		left: 5px;
		top: 90px;
	}
`;

const Next = styled(Button)`
	position: fixed;
	right: 20px;
	top: 100px;
	@media screen and (max-width: ${props => props.theme.device.mobile}) {
		right: 5px;
		top: 90px;
	}
`;

const ProjectThumbnail = styled.div`
	width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	margin: 0 auto;
	@media screen and (max-width: ${props => props.theme.device.mobile}) {
		width: 86%;
	}
	&:first-child {
		padding-top: 124px;
		@media screen and (max-width: ${props => props.theme.device.mobile}) {
			padding-top: 150px;
		}
	}
	.description {
		img {
			width: 50px;
		}
	}
`;

const Info = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	width: 100%;
	max-width: 500px;
	z-index: 100;
	.photo-description {
		color: ${props => props.theme.color.grey_30};
		text-align: center;
		width: 100%;
	}
	img {
		box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
		border-radius: 12px;
	}
	> div:first-child {
		margin-bottom: 16px;
		@media screen and (max-width: ${props => props.theme.device.mobile}) {
			font-size: 7vw;
		}
	}
	.date {
		color: ${props => props.theme.color.grey_40};
		margin: 8px 0 5px 0;
	}
	.subtitle {
		color: ${props => props.theme.color.grey_40};
	}
	.role {
		margin-top: 0px;
		font-family: Rajdhani-SemiBold;
	}
	@media screen and (max-width: ${props => props.theme.device.mobile}) {
	}
`;

const Wall = styled.div`
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	background-color: #f3f9f9;
	overflow: hidden;
	margin: 90px 0;
	flex-wrap: wrap;
	max-height: 80vh;
`;

const GalleryItem = styled.img`
	min-width: 150px;
	width: 20%;
	max-width: 350px;
	margin: 0 18px 18px 0;
`;

const Minus = styled.div`
	width: 42px;
	height: 2px;
	background-color: ${props => props.theme.color.accent};
	margin: 62px 0;
`;

export default Projects;
