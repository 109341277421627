import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { BrowserRouter as Router } from "react-router-dom";
import Routes from "Routes";
import "style.css";
// State
import { initialState, StateProvider } from "state";
// Reducers
import reducer from "reducers";
// CSS
import "normalize.css";
// Design tokens
import tokens from "tokens";
// Components
import Nav from "components/organisms/Nav";
import Footer from "components/organisms/Footer";
import Pointer from "components/atoms/Pointer";
// import Text from "components/atoms/Text";

const App = () => {
	return (
		<StateProvider initialState={initialState} reducer={reducer}>
			<ThemeProvider theme={tokens}>
				<Pointer />
				<Router>
					<Portfolio>
						<Nav />
						<Routes />
						{/*<Recap>
							<Text>Designed and coded by</Text>
							<Text>Gabriele Rapone</Text>
						</Recap>*/}
						<Footer />
					</Portfolio>
				</Router>
			</ThemeProvider>
		</StateProvider>
	);
};

// Styled components
const Portfolio = styled.div`
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: relative;
`;

const Recap = styled.div`
	position: fixed;
	bottom: 82px;
	left: 40px;
	> div {
		font-size: 0.9rem;
	}
	> div:nth-child(1) {
		font-size: 13px;
	}
	> div:nth-child(2) {
		font-family: Rajdhani-SemiBold;
	}
	@media screen and (max-width: ${props => props.theme.device.mobile}) {
		bottom: 20px;
		left: 20px;
	}
`;

export default App;
