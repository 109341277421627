import React from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import styled from "styled-components";
import { useTransition, animated } from "react-spring";
// Views
import Welcome from "views/Welcome";
import UXGO from "views/UXGO";
import OneFlag from "views/OneFlag";
import Heroport from "views/Heroport";
import Ferrero from "views/Ferrero";

const Routes = () => {
	const location = useLocation();
	const transitions = useTransition(location, location => location.pathname, {
		from: { opacity: 0, transform: "scale(1.2)" },
		enter: { opacity: 1, transform: "scale(1)" },
		leave: { opacity: 0, transform: "scale(0.8)" },
	});

	return (
		<Container>
			{transitions.map(({ item: location, props, key }) => (
				<Transition key={key} style={props}>
					<Switch location={location}>
						<Route exact path="/" component={Welcome} />
						<Route exact path="/UXGO" component={UXGO} />
						<Route exact path="/OneFlag" component={OneFlag} />
						<Route exact path="/Heroport" component={Heroport} />
						<Route exact path="/Ferrero" component={Ferrero} />
					</Switch>
				</Transition>
			))}
		</Container>
	);
};

// Styled components
const Container = styled.div``;

const Transition = styled(animated.div)`
	> div {
		will-change: transform, opacity;
	}
`;

export default Routes;
