const reducer = (state, action) => {
	switch (action.type) {
		case "all":
			return action.all;

		case "data":
			return {
				...state,
				data: action.data,
			};

		case "theme":
			return {
				...state,
				theme: action.theme,
			};

		case "isHover":
			return {
				...state,
				isHover: action.isHover,
			};

		default:
			return state;
	}
};

export default reducer;
