// Colors
const black = "#000000";
const grey_50 = "#333333";
const grey_40 = "#9E9E9E";
const grey_30 = "#BDBDBD";
const grey_20 = "#DCDCDC";
const grey_10 = "#F2F2F2";
const white = "#FFFFFF";
const primary = "#000000";
const accent = "#C7B38E";
const error = "#EB5757";
const green = "#6fcf97";

// Typography
const h1 = "3vw";
const h2 = "2rem";
const h3 = "1.5rem";
const h4 = "1.25rem";
const base = "1rem";
const label = ".80rem";
const detail = ".75rem";

// Animation timing
export const timing = {
	fast: 200,
	normal: 325,
	slow: 400,
};

// Transitions
export const bezier = "cubic-bezier(.05,.17,.52,1.02)";

export const transition = {
	fast: `${timing.fast} ${bezier}`,
	normal: `${timing.normal} ${bezier}`,
	slow: `${timing.slow} ${bezier}`,
};

// Design tokens
const tokens = {
	color: {
		black,
		grey_50,
		grey_40,
		grey_30,
		grey_20,
		grey_10,
		white,
		primary,
		error,
		alert,
		accent,
		green,
	},
	typography: {
		h1: {
			size: h1,
			transform: "auto",
			lineHeight: "85%",
			family: "Chaney_Extra",
		},
		h2: {
			size: h2,
			transform: "auto",
			lineHeight: "25px",
			family: "Rajdhani-Bold",
		},
		h3: {
			size: h3,
			transform: "auto",
			lineHeight: "25px",
			family: "Rajdhani-Bold",
		},
		h4: {
			size: h4,
			transform: "auto",
			lineHeight: "1.75rem",
			family: "Rajdhani-SemiBold",
		},
		base: {
			size: base,
			transform: "auto",
			lineHeight: "1.40rem",
			family: "Rajdhani-Regular",
		},
		label: {
			size: label,
			transform: "uppercase",
			lineHeight: "25px",
			family: "Rajdhani-Bold",
		},
		detail: {
			size: detail,
			transform: "uppercase",
			lineHeight: "25px",
			family: "Rajdhani-Medium",
		},
	},
	button: {
		primary: {
			bgColor: primary,
			color: white,
			size: label,
			padding: "21px 34px",
			spacing: ".2px",
		},
		secondary: {
			bgColor: black,
			color: white,
			size: label,
			padding: "21px 34px",
			spacing: ".2px",
		},
		tertiary: {
			bgColor: "transparent",
			color: black,
			size: label,
			padding: "21px 20px",
			spacing: ".2px",
		},
	},
	spacing: {
		tiny: "8px",
		small: "16px",
		base: "24px",
		large: "32px",
		xlarge: "64px",
	},
	icon_size: {
		small: "16px",
		base: "24px",
		large: "32px",
		xlarge: "48px",
	},
	transition: {
		fast: `${timing.fast}ms ${bezier}`,
		normal: `${timing.normal}ms ${bezier}`,
		slow: `${timing.slow}ms ${bezier}`,
	},
	device: {
		mobile: "480px",
		tablet: "768px",
		laptop: "1440px",
		desktop: "1920px",
	},
	padding: {
		mobile: "10px",
		tablet: "15px",
		desktop: "20px",
	},
};

export default tokens;
